
import ArticleDetail from '@/components/ui/ArticleDetail.vue';
import { defineComponent, ref } from 'vue';
import { StoryblokResult, StoryData } from 'storyblok-js-client';
import { ArticleModel } from '@/models/article.model';
import { RouteLocationNormalizedLoaded, Router, useRoute, useRouter } from 'vue-router';
import { fetchArticle } from '@/composables/api';
import { formatDateFromArticleStory, parseStoryblokResultToEntity } from '@/composables/storyblokResultParser';
import useSlugWatch from '@/composables/useSlugWatch';

export default defineComponent({
  components: {
    ArticleDetail,
  },
  setup() {
    const article = ref<StoryData<ArticleModel>>();
    const route: RouteLocationNormalizedLoaded = useRoute();
    const router: Router = useRouter();

    const loadArticle = async () => {
      const res: StoryblokResult = await fetchArticle(route.params.slug as string);

      const story: StoryData<ArticleModel> = parseStoryblokResultToEntity<StoryData<ArticleModel>>(res);

      if (story) {
        article.value = formatDateFromArticleStory(story);
      } else {
        router.push('/articles');
      }
    };

    useSlugWatch(loadArticle);

    return { article };
  },
});
