import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';
import { watch } from 'vue';

export default function(callback: Function) {
  const route: RouteLocationNormalizedLoaded = useRoute();

  watch(
    () => route.params.slug,
    () => {
      callback();
    },
    { immediate: true, flush: 'post' }
  );
}
