<template>
  <header>
    <div class="mb-4">
      <AppTag v-for="tag in tags" :key="tag" data-testid="topic-link" :url="`/topics/${tagSlug(tag)}`">
        {{ tag }}
      </AppTag>
    </div>
    <div class="uppercase text-xs">{{ date }}</div>
    <AppTitle>{{ title }}</AppTitle>
    <div class="flex items-center mt-6">
      <ArticleAuthors :authors="authors" />
    </div>
  </header>
  <video v-if="videoUrl" :src="videoUrl" controls width="450" class="my-4"></video>
  <div class="md-body prose" v-html="contentWithToc" />
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import kebabCase from 'lodash/kebabCase';
import { AuthorModel } from '@/models/author.model';
import MarkdownIt from 'markdown-it';
import markdownItGithubToc from 'markdown-it-github-toc';
import AppTag from '@/components/ui/AppTag.vue';
import ArticleAuthors from '@/components/ui/ArticleAuthors.vue';
import AppTitle from '@/components/ui/AppTitle.vue';

const md = MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
}).use(markdownItGithubToc, {
  tocFirstLevel: 2,
  anchorLink: false,
});

export default defineComponent({
  components: { AppTitle, ArticleAuthors, AppTag },
  props: {
    title: {
      type: String,
      default: (): string => '',
    },
    authors: {
      type: [Array, Object] as PropType<AuthorModel | AuthorModel[]>,
      default: (): Array<AuthorModel> => [],
    },
    date: {
      type: String,
      default: (): string => '',
    },
    videoUrl: {
      type: String,
      default: (): string => '',
    },
    content: {
      type: String,
      default: (): string => '',
    },
    tags: {
      type: Array,
      default: (): string[] => [],
    },
  },
  setup(props) {
    const contentWithToc = computed(() => {
      return md.render(`[toc] \n ${props.content}`);
    });

    const tagSlug = (tag: string): string => {
      return kebabCase(tag);
    };

    return { contentWithToc, tagSlug };
  },
});
</script>
<style>
[id] {
  scroll-margin-top: 122px;
}
</style>
