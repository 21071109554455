<template>
  <router-link :to="url" class="rounded-full text-white bg-main uppercase text-sm mr-2 px-2 py-1"
    ><slot></slot
  ></router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    url: {
      type: String,
      default: (): string => '',
    },
  },
});
</script>
